<template>
  <div class="row">
    <div
      class="col-sm-12 col-md-4 p-3"
      v-for="(partner, index) in allPartners"
      :key="index"
    >
      <div class="card shadow partner-card">
        <div class="card-body">
          <img
            :src="getPartnerProPic(partner.partnerImg)"
            class="partner-image"
            alt="Profile Picture"
          />

          <br />

          <h5 class="mt-2">{{ partner.name }}</h5>
          <p class="mb-1">{{ partner.country }}</p>
          <p class="mb-2">{{ partner.address }}</p>
          <p>{{ partner.engagement }}</p>

          <a
            :href="partner.googlePageUrl"
            class="btn btn-warning text-white w-100"
            target="_blank"
          >
            Click here to visit
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import defaultPartnerPic from "../assets/images/no-image.jpg";

export default {
  name: "our-partners",
  data() {
    return {
      allPartners: [],
    };
  },
  mounted() {
    this.getAllPatners();
  },
  methods: {
    getAllPatners() {
      let loader = this.$loading.show();

      axios
        .get(
          "https://8uxify1xrj.execute-api.ap-southeast-1.amazonaws.com/prod/partners/?addedBy=all"
        )
        .then((response) => {
          console.log(response);
          this.allPartners = response.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getPartnerProPic(partnerImg) {
      console.log();
      //Check Have Upload Profile Picture
      if (partnerImg && partnerImg != "") {
        return partnerImg + "?v=" + Math.random();
      } else {
        return defaultPartnerPic;
      }
      //Check Have Upload Profile Picture
    },
  },
};
</script>

<style>
.partner-card {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.partner-card :hover {
  opacity: 0.9;
}

.partner-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
}
</style>
